import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import CareerListing from '../components/CareerListing';
import ModularBlocks from '../components/ModularBlocks';

const CareersPage = () => {
  const {
    datoCmsCareersArchive: {
      seoMetaTags,
      title,
      bannerText,
      interestHeading,
      interestContent,
      interestLink,
      noOpenPositionsContent,
      modularBlocks,
    },
    allDatoCmsCareer,
    allDatoCmsDepartment,
  } = useStaticQuery(graphql`
    query CareersPageQuery {
      datoCmsCareersArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
        interestHeading
        interestContent
        interestLink
        noOpenPositionsContent
        modularBlocks {
          ...ContentCardsCarouselModularBlockFragment
          ...ContentCardsModularBlockFragment
          ...ContentModularBlockFragment
          ...FeaturedInformationCardsModularBlockFragment
          ...FullWidthImageModularBlockFragment
          ...ImageCarouselModularBlockFragment
          ...QuoteModularBlockFragment
          ...TestimonialsModularBlockFragment
        }
      }
      allDatoCmsCareer {
        nodes {
          id
          title
          externalLink
          department {
            id
            title
            slug
          }
        }
      }
      allDatoCmsDepartment(sort: { fields: position }) {
        nodes {
          id
          title
          slug
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner overline="Careers" heading={title} text={bannerText} />
        <CareerListing
          listings={allDatoCmsCareer.nodes}
          departments={allDatoCmsDepartment.nodes}
          interestHeading={interestHeading}
          interestContent={interestContent}
          interestLink={interestLink}
          noOpenPositionsContent={noOpenPositionsContent}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default CareersPage;
