import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Link, Svg, Button } from './ui';
import arrowIcon from '../images/arrow.svg';

const StyledCareerListing = styled.section`
  ${sectionMargins(undefined, '60px')};
`;

const StyledSectionHeading = styled.h2`
  ${fluidFontSize(
    '30px',
    '45px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  text-align: center;
`;

const StyledDepartments = styled.div`
  margin-top: 20px;

  ${minBreakpointQuery.medium`
    margin-top: 25px;
  `};
`;

const StyledDepartment = styled.div`
  padding: 20px;
  background-color: ${standardColours.white};
  margin-bottom: 15px;

  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    padding: 25px;
    margin-bottom: 25px;
  `};
`;

const StyledHeading = styled.h3`
  ${fontSize(26)}

  ${minBreakpointQuery.medium`
    ${fontSize(30)}
  `};
`;

const StyledIcon = styled(Svg)`
  margin-right: ${({ right }) => (right ? '0' : '10')}px;
  margin-left: ${({ right }) => (right ? '10' : '0')}px;
  height: 14px;
  width: 18px;
  fill: ${({ right }) =>
    right ? `${brandColours.slate[200]}` : `${brandColours.slate[300]}`};
`;

const StyledJob = styled(OutboundLink)`
  display: block;
  margin: 15px 0;

  &:last-child {
    ${minBreakpointQuery.medium`
      margin-bottom: 5px;
    `};
  }

  ${minBreakpointQuery.medium`
    margin: 25px 0;
    ${fontSize(20)}
  `};
`;

const StyledContent = styled.p`
  margin: 15px 0;
  max-width: ${({ alt }) => (alt ? '680' : '380')}px;

  ${minBreakpointQuery.medium`
    margin: 20px 0;
    ${fontSize(18)}
  `};
`;

const StyledInterestLink = styled(Button)`
  margin-bottom: 10px;

  ${maxBreakpointQuery.medium`
    margin-bottom: 15px;
  `};
`;

const StyledDepartmentLink = styled(Link)`
  display: flex;
  align-items: center;
  text-align: right;
  ${brandFonts.futuraPTDemi()};
  margin: 10px 0 0 10px;
  white-space: nowrap;
`;

const StyledInterest = styled.div`
  padding: 20px;
  background-color: ${brandColours.slate[500]};
  color: ${standardColours.white};

  ${minBreakpointQuery.medium`
    padding: 25px;
  `};
`;

const CareerListing = ({
  listings,
  departments,
  interestHeading,
  interestContent,
  interestLink,
  noOpenPositionsContent,
}) => {
  // Group listings by departments
  const jobListings = [];
  const departmentListings = [];
  const departmentIndexes = []; // Track indexes to remove later

  listings.map(({ title, externalLink, department }) => {
    // Group jobs by department
    if (jobListings[department.title]) {
      jobListings[department.title].push({ title: title, slug: externalLink });
    } else {
      jobListings[department.title] = [{ title: title, slug: externalLink }];

      // Add to new departmentListings array
      // Get original index
      let departmentIndex = departments.findIndex(
        e => e.title === department.title
      );

      if (departmentIndex !== -1) {
        departmentListings.push(departments[departmentIndex]);
        departmentIndexes.push(departmentIndex);
      }
    }

    return jobListings;
  });

  // If no job listings set as original array
  if (departmentListings) {
    // Clean up
    departmentListings.push(...departments);
    departmentIndexes.sort(); // get in numerical order to remove
    for (var i = departmentIndexes.length - 1; i >= 0; i--) {
      departmentListings.splice(
        departmentIndexes[i] + departmentIndexes.length,
        1
      );
    }
  } else {
    departmentListings.push(...departments);
  }

  return (
    <StyledCareerListing>
      <Container narrow={true}>
        <StyledSectionHeading>Open positions</StyledSectionHeading>
        {departments.length > 0 && (
          <StyledDepartments>
            {departmentListings.map(({ title, slug, id }) => (
              <StyledDepartment key={id}>
                <div>
                  <StyledHeading>{title}</StyledHeading>
                  {jobListings[`${title}`] ? (
                    jobListings[`${title}`].map(({ title, slug, id }) => (
                      <StyledJob
                        key={id}
                        href={slug}
                        target="_blank"
                        rel="noopener"
                      >
                        <StyledIcon image={arrowIcon} />
                        {title}
                      </StyledJob>
                    ))
                  ) : (
                    <>
                      <StyledContent>{noOpenPositionsContent}</StyledContent>
                      <StyledInterestLink
                        href={interestLink}
                        target="_blank"
                        rel="noopener"
                      >
                        Register interest
                      </StyledInterestLink>
                    </>
                  )}
                </div>
                <div>
                  <StyledDepartmentLink to={`departments/${slug}`}>
                    View department
                    <StyledIcon image={arrowIcon} right={true} />
                  </StyledDepartmentLink>
                </div>
              </StyledDepartment>
            ))}
          </StyledDepartments>
        )}
        <StyledInterest>
          <StyledHeading>{interestHeading}</StyledHeading>
          <StyledContent alt={true}>{interestContent}</StyledContent>
          <StyledInterestLink
            href={interestLink}
            target="_blank"
            rel="noopener"
            alt={true}
          >
            Register interest
          </StyledInterestLink>
        </StyledInterest>
      </Container>
    </StyledCareerListing>
  );
};

export default CareerListing;
